import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import {
    animationSpeed,
    skeletonBackgroundColor,
    skeletonForegroundColor,
    textBlockBorderRadius,
} from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.constants';
import * as Styled from './title-loader.styles';

export const TitleLoader = (props: IContentLoaderProps) => (
    <ContentLoader
        speed={animationSpeed}
        width={'100%'}
        height={42}
        viewBox="0 0 100 42"
        backgroundColor={skeletonBackgroundColor}
        foregroundColor={skeletonForegroundColor}
        {...props}
    >
        <Styled.TitleRect x="0" y="0" rx={textBlockBorderRadius} ry={textBlockBorderRadius} height={42} />
    </ContentLoader>
);
